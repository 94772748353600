import React from 'react'
import User from '../../Component/admin/UserManagement'

function UserManagement() {
    return (
        <div>
            <User />
        </div>
    )
}

export default UserManagement