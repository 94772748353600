import React from 'react'
import AppliedJobs from '../../Component/user/home/AppliedJobs'
function AppliedJobsPage() {
  return (
    <div>
      <AppliedJobs/>
    </div>
  )
}

export default AppliedJobsPage
