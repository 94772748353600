import React from 'react'
import Chat from '../../Component/user/home/Chat'
import Nav from '../../Component/user/home/Nav'
function ChatPage() {
  return (
    <div>
      <Nav/>
      <Chat/>
    </div>
  )
}

export default ChatPage
