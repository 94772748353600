import React from 'react'
import Signup from '../../Component/user/auth/Signup'

function SignupPage() {
    return (
        <div>
            <Signup />
        </div>
    )
}

export default SignupPage