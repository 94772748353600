import React from 'react'
import NewJob from '../../Component/recruiter/home/NewJob'
import Nav from '../../Component/recruiter/home/Nav'
function NewJobPage() {
  return (
    <div>
      <Nav/>
      <NewJob/>
    </div>
  )
}

export default NewJobPage
