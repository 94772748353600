import React from 'react'
import Profile from '../../user/home/Profile'
function UserProfile() {
  return (
    <div>
      <Profile/>
    </div>
  )
}

export default UserProfile
