import React from 'react'
import Home from '../../Component/recruiter/home/Home'
function RecHome() {
  return (
    <div>
      <Home/>
    </div>
  )
}

export default RecHome
