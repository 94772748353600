import React from 'react'
import UserProfile from '../../Component/recruiter/home/UserProfile'
import Nav from '../../Component/recruiter/home/Nav'
function UserProfilePage() {
  return (
    <div>
        <Nav/>
      <UserProfile/>
    </div>
  )
}

export default UserProfilePage
