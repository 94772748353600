import React from 'react'
import EditJob from '../../Component/recruiter/home/EditJob'
function EditjobPage() {
  return (
    <div>
      <EditJob/>
    </div>
  )
}

export default EditjobPage
