import React from 'react'
import Profile from '../../Component/user/home/RecruiterProfile'
import Nav from '../../Component/user/home/Nav'
function RecruiterProfile() {
  return (
    <div>
        <Nav/>
      <Profile/>
    </div>
  )
}

export default RecruiterProfile
