import React from 'react'
import Home from '../../Component/user/home/Home'
import Nav from '../../Component/user/home/Nav'
function HomePage() {
  return (
    <div>
      <Home/>
    </div>
  )
}

export default HomePage
