import React from 'react'
import Candidate from '../../Component/recruiter/home/Candidate'
function CandidatePage() {
  return (
    <div>
      <Candidate/>
    </div>
  )
}

export default CandidatePage
