import React from 'react'
import Job from '../../Component/user/home/Job'

function JobPage() {
    return (
        <div>
            <Job />
        </div>
    )
}

export default JobPage