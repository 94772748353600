import React from 'react'
import Profile from '../../Component/recruiter/home/Profile'
import Nav from '../../Component/recruiter/home/Nav'
function ProfilePage() {
  return (
    <div>
      <Nav/>
      <Profile/>
    </div>
  )
}

export default ProfilePage
