import React from 'react'
import Home from '../../Component/admin/AdminHome'

function LoginPage() {
    return (
        <div>
            <Home />
        </div>
    )
}

export default LoginPage