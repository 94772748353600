import React from 'react'
import SaveJobs from '../../Component/user/home/SaveJobs'
import Nav from '../../Component/user/home/Nav'
function SaveJobPage() {
  return (
    <div>
      <Nav/>
      <SaveJobs/>
    </div>
  )
}

export default SaveJobPage
