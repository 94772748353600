import React from 'react'
import Profile from '../../Component/user/home/Profile'
import Nav from '../../Component/user/home/Nav'
function ProfilePage() {
    return (
        <div>
            <Nav/>
            <Profile />
        </div>
    )
}

export default ProfilePage