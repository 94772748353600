import React from 'react'
import Profile from '../../recruiter/home/Profile'
function RecruiterProfile() {
  return (
    <div>
      <Profile/>
    </div>
  )
}

export default RecruiterProfile
