import React from 'react'
import RecLogin from '../../Component/recruiter/auth/RecLogin'
function RecLoginPage() {
  return (
    <div>
      <RecLogin/>
    </div>
  )
}

export default RecLoginPage
